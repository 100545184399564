<template>
  <div class="home">
    <van-button url="/class"  style="width: 100%" type="primary"
      >查看分类</van-button
    >
    <van-button url="/list" style="width: 100%" type="primary"
      >查看产品</van-button
    >
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.home {
  padding:20px
}
.van-button {
  margin: 20px 0
}
</style>